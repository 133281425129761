import CustomLink from "../CustomLink";

const ExtraInformationColumn = ({value, record, options}) => {
    return (
        <div className={"text-center"}>
            {options?.key_type === 'link' &&
                <span className={"text-purple-bright fw-bold"}>
                    <CustomLink target={'_blank'}
                                to={record?.extra_information?.[`${options?.custom_key}`]}>Link</CustomLink>
                </span>
            }
            {options?.ket_type === 'text' &&
                <span>{record?.extra_information?.[`${options?.custom_key}`]}</span>
            }
        </div>
    )
}

export default ExtraInformationColumn