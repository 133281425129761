import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getCurrentBranchImportInfo = async () => {
    return await (new Api()).get(`/import/info`, {}, getAuthHeader());
}

export const validateImportAction = async ({entity,file,setLoading}) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", file);

    return await (new Api()).post(`/import/validate/${entity}`, formData, getAuthHeader());
}

export const importAction = async ({data,entity,setLoading}) => {
    setLoading(true)
    return await (new Api()).post(`/import/${entity}`, data, getAuthHeader());
}