import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {Avatar, Button, Card, Col, Row, Tabs} from "antd";
import Title from "antd/es/skeleton/Title";
import TabPane from "antd/es/tabs/TabPane";
import {useEffect, useState} from "react";
import {getOneStaffAction} from "../../_api/StaffApi";
import {useParams} from "react-router-dom";
import {getOneStudentAction} from "../../_api/StudentApi";

const ShowStudentScreen = () => {
    const [student,setStudent] = useState({})
    const {id} = useParams()
    useEffect(() => {
        getOneStudentAction(id).then(r => {
            if (r?.success) {
                setStudent(r?.data)
            }
        })
    }, []);

    const userInfo = {
        coverPhoto: "https://asset.gecdesigns.com/img/wallpapers/beautiful-magical-misty-mountains-reflection-river-ultra-hd-wallpaper-4k-sr10012420-1706505766369-cover.webp", // Placeholder cho ảnh bìa
    };

    return (
        <AuthenticatedLayout
            breadcrumb={{
                first:{
                    label:"Học sinh",
                    to:"/student"
                }
            }}
        >
            <div style={{fontFamily: "Arial, sans-serif"}}>
                {/* Header */}
                <div style={{position: "relative"}}>
                    <img
                        src={userInfo.coverPhoto}
                        alt="Cover"
                        style={{width: "100%", height: "250px", objectFit: "cover"}}
                    />
                    <Avatar
                        src={student.avatar}
                        size={100}
                        style={{
                            position: "absolute",
                            bottom: "-50px",
                            left: "20px",
                            border: "3px solid white",
                        }}
                    />
                </div>

                {/* User Info */}
                <div style={{padding: "70px 20px 20px", background: "#fff"}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 style={{margin: 0}}>{student.name}</h2>
                            <p style={{margin: 0, color: "gray"}}>{student?.extra_infomation?.chuc_vu}</p>
                        </Col>
                        <Col>
                            {/*<Button type="primary" style={{marginRight: "10px"}}>*/}
                            {/*    Thêm bạn bè*/}
                            {/*</Button>*/}
                            <Button type="default">Nhắn tin</Button>
                        </Col>
                    </Row>
                </div>

                {/* Tabs */}
                <div style={{marginTop: "20px", background: "#fff", padding: "0 20px"}}>
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="Thông tin" key="2">
                            <div>
                                <span className={"fw-bold"}>Mã học sinh</span><span>: {student?.uuid}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Trạng thái</span><span>: {student?.student_status_name}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Giới tính</span><span>: {student?.gender}</span>
                            </div>

                            <div>
                                <span className={"fw-bold"}>Ngày sinh</span><span>: {student?.date_of_birthday}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Số ngày còn lại tới sinh nhật</span><span>: {student?.my_birthday}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Tuổi</span><span>: {student?.age}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Số điện thoại</span><span>: {student?.phone}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Email</span><span>: {student?.email}</span>
                            </div>
                        </TabPane>
                        <TabPane tab="Thông tin phụ huynh" key="3">
                            <div>
                                <span
                                    className={"fw-bold"}>Họ và tên mẹ</span><span>: {student?.extra_information?.['ho-va-ten-bo']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Ngành sinh mẹ</span><span>: {student?.extra_information?.['ngay-sinh-bo']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Email của mẹ</span><span>: {student?.extra_information?.['email-bo']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>FB của mẹ</span><span>: {student?.extra_information?.['facebook-bo']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Nghề nghiệp mẹ</span><span>: {student?.extra_information?.['nghe-nghiep-bo']}</span>
                            </div>
                            <div>
                                <div className={"fw-bold"}>Mô tả:</div>
                                <blockquote>
                                    {student?.extra_information?.['mo-ta-so-thich-tinh-cach-moi-quan-tam-cua-bo']}
                                </blockquote>
                            </div>
                            <hr/>
                            <div>
                                <span
                                    className={"fw-bold"}>Họ và tên mẹ</span><span>: {student?.extra_information?.['ho-va-ten-me']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Ngành sinh mẹ</span><span>: {student?.extra_information?.['ngay-sinh-me']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Email của mẹ</span><span>: {student?.extra_information?.['email-me']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>FB của mẹ</span><span>: {student?.extra_information?.['facebook-me']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Nghề nghiệp mẹ</span><span>: {student?.extra_information?.['nghe-nghiep-me']}</span>
                            </div>
                            <div>
                                <div className={"fw-bold"}>Mô tả:</div>
                                <blockquote>
                                    {student?.extra_information?.['mo-ta-so-thich-tinh-cach-moi-quan-tam-cua-me']}
                                </blockquote>
                            </div>
                        </TabPane>
                        <TabPane tab="Thông tin thẻ học" key="4">
                            <div className={"fw-bold"}>Đã có lớp học</div>
                            {student?.['card_class']?.map((object,key) =>
                                <div key={key} className={"border mb-3 p-1"}>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Mã thẻ học: </span>
                                        <span>{object?.card?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Mã lớp: </span>
                                        <span>{object?.classroom?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Tổng số buổi được đăng ký: </span>
                                        <span>{object?.card?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Số buổi còn lại: </span>
                                        <span>{object?.card?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Đã thanh toán: </span>
                                        <span>{object?.card?.uuid}</span>
                                    </div>
                                </div>


                            )}
                            <div className={"fw-bold"}>Chưa gắn lớp học</div>
                            {student?.['card_no_class']?.map((object,key) =>
                                <div key={key} className={"border mb-3 p-1"}>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Mã thẻ học: </span>
                                        <span>{object?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Tổng số buổi được đăng ký: </span>
                                        <span>{object?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Số buổi còn lại: </span>
                                        <span>{object?.uuid}</span>
                                    </div>
                                    <div className={"fw-bold"}>
                                        <span className={"fw-bold"}>Đã thanh toán: </span>
                                        <span>{object?.uuid}</span>
                                    </div>
                                </div>
                            )}
                        </TabPane>

                    </Tabs>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default ShowStudentScreen