import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {Avatar, Button, Card, Col, Row, Tabs} from "antd";
import Title from "antd/es/skeleton/Title";
import TabPane from "antd/es/tabs/TabPane";
import {useEffect, useState} from "react";
import {getOneStaffAction} from "../../_api/StaffApi";
import {useParams} from "react-router-dom";

const ShowStaffScreen = () => {
    const [staff,setStaff] = useState({})
    const {id} = useParams()
    useEffect(() => {
        getOneStaffAction(id).then(r => {
            if (r?.success) {
                setStaff(r?.data)
            }
        })
    }, []);

    const userInfo = {
        coverPhoto: "https://asset.gecdesigns.com/img/wallpapers/beautiful-magical-misty-mountains-reflection-river-ultra-hd-wallpaper-4k-sr10012420-1706505766369-cover.webp", // Placeholder cho ảnh bìa
    };

    return (
        <AuthenticatedLayout
            breadcrumb={{
                first:{
                    label:"Nhân viên",
                    to:"/staff"
                }
            }}
        >
            <div style={{fontFamily: "Arial, sans-serif"}}>
                {/* Header */}
                <div style={{position: "relative"}}>
                    <img
                        src={userInfo.coverPhoto}
                        alt="Cover"
                        style={{width: "100%", height: "250px", objectFit: "cover"}}
                    />
                    <Avatar
                        src={staff.avatar}
                        size={100}
                        style={{
                            position: "absolute",
                            bottom: "-50px",
                            left: "20px",
                            border: "3px solid white",
                        }}
                    />
                </div>

                {/* User Info */}
                <div style={{padding: "70px 20px 20px", background: "#fff"}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 style={{margin: 0}}>{staff.name}</h2>
                            <p style={{margin: 0, color: "gray"}}>{staff?.extra_infomation?.chuc_vu}</p>
                        </Col>
                        <Col>
                            {/*<Button type="primary" style={{marginRight: "10px"}}>*/}
                            {/*    Thêm bạn bè*/}
                            {/*</Button>*/}
                            <Button type="default">Nhắn tin</Button>
                        </Col>
                    </Row>
                </div>

                {/* Tabs */}
                <div style={{marginTop: "20px", background: "#fff", padding: "0 20px"}}>
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="Thông tin" key="2">
                            <div>
                                <span className={"fw-bold"}>Mã nhân viên</span><span>: {staff?.uuid}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Trạng thái</span><span>: {staff?.staff_status_name}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Ngày tháng năm sinh</span><span>: {staff?.birthday}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Tuổi</span><span>: {staff?.age}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Số điện thoại</span><span>: {staff?.phone}</span>
                            </div>
                            <div>
                                <span className={"fw-bold"}>Email</span><span>: {staff?.email}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Facebook</span><span>: {staff?.extra_information?.['facebook-nhan-vien']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Địa chỉ (quê quán, tỉnh thành...)	</span><span>: {staff?.extra_information?.['dia-chi-thuong-tru-nhan-vien']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Tên ngân hàng nhận lương</span><span>: {staff?.extra_information?.['ten-ngan-hang-nhan-luong-cua-nhan-vien']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Số tài khoản ngân hàng</span><span>: {staff?.extra_information?.['so-tai-khoan-ngan-hang-nhan-luong-nhan-vien']}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Tên chủ tài khoản ngân hàng</span><span>: {staff?.extra_information?.['ten-chu-tai-khoan-ngan-hang-nhan-luong-nhan-vien']}</span>
                            </div>
                        </TabPane>
                        <TabPane tab="Bài viết" key="1">
                            <p>Tính năng đang phát triển</p>
                        </TabPane>

                    </Tabs>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default ShowStaffScreen