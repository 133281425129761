import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams, mapSelectData} from "../../_classes/Helper";
import {getAllStudentsAction} from "../../_api/StudentApi";
import {getAllSharedClassroomsAction} from "../../_api/ClassroomApi";

const ListStudentScreen = () => {
    const location = useLocation();
    const [studentCollection, setStudentCollection] = useState({})
    const [sharedClassrooms, setSharedClassrooms] = useState([])
    const nav = useNavigate()
    const [query, setQuery] = useState()
    useEffect(() => {
        getAllSharedClassroomsAction({
            fields: 'id,uuid,name'
        }).then((r) => {
            console.log(r?.data)
            if (r?.success) {
                setSharedClassrooms(r?.data?.data)
            }
        })
    }, [])
    const columnStruct = [
        {
            title: 'Học sinh',
            dataIndex: 'id',
            key: 'id',
            type: "user_info",
            fixed: 'left',
            width: 250
        },
        {
            title: 'Trạng thái',
            dataIndex: 'student_status_name',
            key: 'student_status_name',
            width: 100
        },
        {
            title: 'Liên hệ học sinh',
            dataIndex: 'student_contact',
            key: 'student_contact',
            type: 'student_contact',
            width: 150
        },
        {
            title: 'Thông tin bố',
            dataIndex: 'father_student_contact',
            key: 'father_student_contact',
            type: 'father_student_contact',
            width: 200
        },
        {
            title: 'Thông tin mẹ',
            dataIndex: 'mother_student_contact',
            key: 'mother_student_contact',
            type: 'mother_student_contact',
            width: 200
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 100
        }
    ];
    const filteringStructs = [
        {
            field: "name",
            type: "text",
            label: "Tên học sinh"
        },
        {
            field: "uuid",
            type: "text",
            label: "Mã học sinh"
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
        },
        {
            field: "email",
            type: "text",
            label: "Email",
        },
        {
            field: "address",
            type: "text",
            label: "Địa chỉ",
        },
        {
            field: "phone_dad",
            type: "text",
            label: "Số điện thoại bố",
        },
        {
            field: "phone_mom",
            type: "text",
            label: "Số điện thoại mẹ",
        },
        {
            field: "age",
            type: "number",
            label: "Tuổi"
        },
        {
            field: "status",
            type: "select",
            label: "Trạng thái",
            options: [
                {value: 1, label: 'Đang học'},
                {value: 2, label: 'Tạm nghỉ'},
                {value: 3, label: 'Đã nghỉ'},
            ]
        },
        {
            field: "day",
            type: "number",
            label: "Ngày sinh"
        },
        {
            field: "month",
            type: "number",
            label: "Tháng sinh"
        },
        {
            field: "year",
            type: "number",
            label: "Năm sinh"
        },
        {
            field: "gender",
            type: "select",
            label: "Giới tính",
            options: [
                {
                    label: 'Nam',
                    value: 1
                },
                {
                    label: 'Nữ',
                    value: 2
                },
            ]
        },
        {
            field: "classroom",
            type: "select",
            label: "Theo lớp học",
            options: mapSelectData({
                key: 'id',
                label: 'uuid',
                prefix: 'name',
                data: sharedClassrooms
            }),
        },
    ]

    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            getAllStudentsAction(query).then((response) => {
                setStudentCollection(response?.data)
            }).catch()
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            fields:"id,uuid,avatar,name,email,phone,verified_code,home_id,age,student_status,student_status_name,extra_information,user_abilities",
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering || null,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách học sinh</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/student/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={studentCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setStudentCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListStudentScreen