import CustomLink from "../CustomLink";
import {Modal} from "antd";
import {useState} from "react";

const StudentContactColumn = ({value, record}) => {
    const [open, setOpen] = useState(false)
    return (
        <div className={"text-start cursor-pointer"}>
            {record?.phone && (
                <div>
                    <span className="fw-bold">SĐT:</span> {record.phone}
                </div>
            )}
            {record?.extra_information?.['facebook-hoc-sinh'] && (
                <div>
                    <span className="fw-bold">Fb:</span>
                    <CustomLink to={record.extra_information['facebook-hoc-sinh']}>Link</CustomLink>
                </div>
            )}
            {record?.email && (
                <div>
                    <span className="fw-bold">Email:</span> {record.email}
                </div>
            )}
        </div>
    )
}


export default StudentContactColumn