import {useNavigate} from "react-router-dom";
import {Modal} from "antd";
import {useState} from "react";

const UserInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const [open, setOpen] = useState(false)
    const handleEdit = () => {
        nav(`/${record?.user_abilities?.entity}/show/${record?.id}`)
    }
    return (
        <div className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"} onClick={() => setOpen(true)}>
                {(record?.avatar || record?.user?.avatar) &&
                    <img alt={"pink"}
                         src={record?.avatar || record?.user?.avatar} style={{width: "2.5rem"}}
                         className={"rounded-circle cursor-pointer"}/>
                }
            </div>
            <div onClick={handleEdit} className={"cursor-pointer"}>
                <div
                    className={"fw-semi text-purple"}>{record?.name?.toUpperCase() || record?.user?.name?.toUpperCase()}</div>
                <div className={"small text-purple"}>{record?.uuid || record?.user?.uuid}</div>
            </div>
            <AvatarModal avatar={record?.avatar || record?.user?.avatar} open={open} setOpen={setOpen}/>
        </div>
    )
}

const AvatarModal = ({avatar, open, setOpen}) => {
    return (
        <Modal
            open={open} onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)} centered={true}
            cancelButtonProps={null}
            keyboard={true}
            footer={false}
            destroyOnClose={true}>
            <img alt={""} src={avatar} style={{width: '30rem'}}/>
        </Modal>
    )
}

export default UserInfoColumnComponent