import CustomLink from "../CustomLink";
import {Modal} from "antd";
import {useState} from "react";

const PhoneColumn = ({value, record}) => {
    const [open, setOpen] = useState(false)
    return (
        <div className={"text-center cursor-pointer"}>
            <span onClick={() => setOpen(true)} className={'text-purple-bright fw-bold'}>{value}</span>
            <PhoneSelectMethodModal open={open} setOpen={setOpen} phone={value}/>
        </div>
    )
}

const PhoneSelectMethodModal = ({open, phone, setOpen}) => {
    return (
        <Modal footer={false} centered={true} open={open} onCancel={()=>setOpen(false)}>
            <div className={"d-flex justify-content-between align-items-center p-2 py-5"}>
                <div className={'cursor-pointer text-center'}>
                    <img
                        src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ86SP3Qgs-uyKvxqd6owBa1UsVW5blMYEzlw&s'}
                        style={{width: 100}}
                        className={"pb-3"}
                    />
                    <div className={'fw-bold'}>Tìm trên Zalo</div>
                </div>
                <div className={'cursor-pointer text-center'}>
                    <img
                        src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXwEJ0_-WZTd1tgKOe85Iq2YenPGy5AicUWg&s'}
                        style={{width: 100,borderRadius:100}}
                        className={"pb-3"}
                    />
                    <div className={'fw-bold'}>Gửi sms</div>
                </div>
                <div className={'cursor-pointer text-center'}>
                    <CustomLink to={`tel:${phone}`}>
                        <img
                            src={'https://play-lh.googleusercontent.com/bINhCcN48tf5pzc5Ld342g1L6kD0yWTGHp7LcTHSsIwOTTvg1XQfrnAfPDqfm6qi'}
                            style={{width: 100}}
                            className={"pb-3"}
                        />
                        <div className={'fw-bold'}>Gọi điện</div>
                    </CustomLink>
                </div>
            </div>
        </Modal>
    )
}

export default PhoneColumn