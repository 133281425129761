import CustomLink from "../CustomLink";

const EmailColumn = ({value, record}) => {
    return (
        <div className={"text-center "}>
           <span className={'text-purple-bright fw-bold'}> <CustomLink to={`mailto:${value}`}>{value}</CustomLink></span>
        </div>
    )
}

export default EmailColumn