import {Select, Table} from "antd";
import Column from "antd/es/table/Column";
import React from "react";
import ActionColumnComponent from "./Columns/ActionColumnComponent";
import ListItemsColumnComponent from "./Columns/ListItemsColumnComponent";
import {DoubleLeftOutlined, DoubleRightOutlined} from "@ant-design/icons";
import UserInfoColumnComponent from "./Columns/UserInfoColumnComponent";
import TeacherSourceColumn from "./Columns/TeacherSourceColumn";
import DateColumn from "./Columns/DateColumn";
import NumberColumn from "./Columns/NumberColumn";
import StudentCardColumnComponent from "./Columns/StudentCardColumnComponent";
import RenewColumn from "./Columns/RenewColumn";
import ClassroomInfoColumnComponent from "./Columns/ClassroomInfoColumnComponent";
import SelectColumn from "./Columns/SelectColumn";
import ClassroomSchedulesColumnComponent from "./Columns/ClassroomSchedules";
import DateTimeColumn from "./Columns/DateTimeColumn";
import CreatorColumnComponent from "./Columns/CreatorColumnComponent";
import StudyLogInfoColumnComponent from "./Columns/StudyLogInfoColumnComponent";
import StatisticColumn from "./Columns/StatisticColumn";
import WorkingShiftsColumnComponent from "./Columns/WorkingShiftsColumn";
import CriteriaNameColumn from "./Columns/CriteriaNameColumn";
import MonthColumn from "./Columns/MonthColumn";
import SalaryInfoColumnComponent from "./Columns/SalaryInfoColumnComponent";
import SalarySheetActionColumn from "./Columns/SalarySheetActionColumn";
import ExtraInformationColumn from "./Columns/ExtraInformationColumn";
import EmailColumn from "./Columns/EmailColumn";
import PhoneColumn from "./Columns/PhoneColumn";
import StudentContactColumn from "./Columns/StudentContactColumn";
import ParentStudentContactColumn from "./Columns/ParentStudentContactColumn";

const DataTableComponent = ({collection, setCollection, query, setQuery, columnStructs, refresh = false, setRefresh = null}) => {
    return (
        <div className={"bsm-data-table position-relative"}>
            <Table
                scroll={{
                    y: 500,
                    // x: 1200,
                }}
                pagination={false}
                bordered
                dataSource={collection?.data}>
                {columnStructs?.map((column) =>
                    <React.Fragment key={column?.key}>
                        {column?.type == null &&
                            <Column className={column?.rootClassName || ''} fixed={column?.fixed}
                                    width={column?.width || 200} title={column?.title}
                                    dataIndex={column?.dataIndex} key={column?.key}/>
                        }
                        {column?.type === 'email' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value) => <EmailColumn value={value}/>}
                            />
                        }
                        {column?.type === 'phone' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value) => <PhoneColumn value={value}/>}
                            />
                        }
                        {column?.type === 'student_contact' &&
                            <Column
                                className={"text-start"}
                                fixed={column?.fixed} width={column?.width || 600} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <StudentContactColumn value={value} record={record}/>}
                            />
                        }
                        {column?.type === 'mother_student_contact' &&
                            <Column
                                className={"text-start"}
                                fixed={column?.fixed} width={column?.width || 600} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <ParentStudentContactColumn value={value} record={record}
                                                                                       type={'mother'}/>}
                            />
                        }
                        {column?.type === 'father_student_contact' &&
                            <Column
                                className={"text-start"}
                                fixed={column?.fixed} width={column?.width || 600} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <ParentStudentContactColumn value={value} record={record}
                                                                                       type={'father'}/>}
                            />
                        }
                        {column?.type === 'number' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value) => <NumberColumn value={value}/>}
                            />
                        }
                        {column?.type === 'list_items' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(lists) => <ListItemsColumnComponent lists={lists}/>}/>
                        }
                        {column?.type === 'user_abilities' &&
                            <Column
                                className={"text-center"}
                                fixed={'right'} width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(user_ability) => <ActionColumnComponent
                                    user_ability={user_ability}
                                    deletedEvent={(id) => {
                                        setCollection({
                                            ...collection,
                                            data: collection?.data?.filter(r => r.id !== id)
                                        })
                                    }}
                                />}/>
                        }
                        {column?.type === 'user_info' &&
                            <Column
                                fixed={'left'} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(id, record) => <UserInfoColumnComponent id={id} record={record}/>}/>
                        }
                        {column?.type === 'salary_info' &&
                            <Column
                                fixed={'left'} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(id, record) => <SalaryInfoColumnComponent id={id} record={record}/>}/>
                        }
                        {column?.type === 'student_card_info' &&
                            <Column
                                fixed={'left'} width={column?.width || 250} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(id, record) => <StudentCardColumnComponent id={id} record={record}/>}/>
                        }
                        {column?.type === 'teacher_source' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(id, record) => <TeacherSourceColumn id={id} record={record}/>}/>
                        }
                        {column?.type === 'date' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <DateColumn value={value} record={record}/>}/>
                        }
                        {column?.type === 'month' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <MonthColumn value={value} record={record}/>}/>
                        }
                        {column?.type === 'datetime' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <DateTimeColumn value={value} record={record}/>}/>
                        }
                        {column?.type === 'renew' &&
                            <Column
                                className={"text-center"}
                                fixed={column?.fixed} width={column?.width || 200} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <RenewColumn value={value} record={record}/>}/>
                        }
                        {column?.type === 'classroom_info' &&
                            <Column
                                fixed={column?.fixed} width={column?.width || 300} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <ClassroomInfoColumnComponent value={value}
                                                                                         record={record}/>}/>
                        }

                        {column?.type === 'classroom_schedules' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 250} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) =>
                                    <ClassroomSchedulesColumnComponent value={value}
                                                                       record={record}/>}
                            />
                        }
                        {column?.type === 'select' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <SelectColumn options={column?.options} value={value}
                                                                         record={record}/>}/>
                        }
                        {column?.type === 'creator' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <CreatorColumnComponent options={column?.options}
                                                                                   value={value} record={record}/>}/>
                        }
                        {column?.type === 'studylog_info' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <StudyLogInfoColumnComponent options={column?.options}
                                                                                        value={value}
                                                                                        record={record}/>}/>
                        }
                        {column?.type === 'statistics' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <StatisticColumn options={column?.options} value={value}
                                                                            record={record}/>}/>
                        }
                        {column?.type === 'working_shifts' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <WorkingShiftsColumnComponent options={column?.options}
                                                                                         value={value}
                                                                                         record={record}/>}/>
                        }
                        {column?.type === 'criteria_name' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-start"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <CriteriaNameColumn options={column?.options} value={value}
                                                                               record={record}/>}/>
                        }
                        {column?.type === 'salary_sheet_action' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-start"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <SalarySheetActionColumn options={column?.options}
                                                                                    value={value}
                                                                                    refresh={refresh}
                                                                                    setRefresh={setRefresh}
                                                                                    record={record}/>}/>
                        }
                        {column?.type === 'extra_information' &&
                            <Column
                                fixed={column?.fixed}
                                className={"text-center"}
                                width={column?.width || 150} title={column?.title}
                                dataIndex={column?.dataIndex} key={column?.key}
                                render={(value, record) => <ExtraInformationColumn options={column?.options}
                                                                                   value={value}
                                                                                   record={record}/>}/>
                        }
                    </React.Fragment>
                )}
            </Table>
            <div className={"mt-4 d-flex justify-content-between"}>
                <div>
                    <button
                        className={"p-1 px-3 me-1 " + (query?.offset > 0 ? "btn-purple-bright" : "btn-purple-disable")}>
                        <DoubleLeftOutlined
                            onClick={() => {
                                if (query?.offset === 0) {
                                    return;
                                }

                                setQuery({
                                    ...query,
                                    offset: parseInt(query?.offset) - parseInt(query?.limit)
                                })
                            }}
                            className={"p-0"}/>
                    </button>
                    <button
                        onClick={() => {
                            if (collection?.has_more === false) {
                                return;
                            }

                            setQuery({
                                ...query,
                                offset: parseInt(query?.offset) + parseInt(query?.limit)
                            })
                        }}
                        className={"p-1 px-3 " + (collection?.has_more === true ? "btn-purple-bright" : "btn-purple-disable")}>
                        <DoubleRightOutlined className={"p-0"}/>
                    </button>
                </div>
                <div>
                    <Select
                        style={{
                            minWidth: "5rem"
                        }}
                        value={query?.limit}
                        onChange={(r) => {
                            console.log('r', r)
                            setQuery({
                                ...query,
                                limit: r
                            })
                        }}
                        options={[
                            {
                                label: "2",
                                value: 2
                            },
                            {
                                label: "10",
                                value: 10
                            },
                            {
                                label: "20",
                                value: 20
                            },
                            {
                                label: "50",
                                value: 50
                            },
                            {
                                label: "100",
                                value: 100
                            },
                            {
                                label: "200",
                                value: 200
                            },
                            {
                                label: "500",
                                value: 500
                            },
                            {
                                label: "1000",
                                value: 1000
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}
export default DataTableComponent