import {Col, Input, InputNumber, Select, Space} from "antd";
import {useEffect, useState} from "react";

const FilteringComponent = ({query, setQuery, filteringStructs}) => {
    const [filteringValue, setFilteringValue] = useState(null);
    useEffect(() => {
        if (query?.filtering) {
            setFilteringValue(query?.filtering)
        }
    }, [query]);
    return (
        <div className={"d-flex flex-wrap w-100"}>
            {filteringStructs?.map((filter, key) =>
                <Col md={filter?.col || 3} key={key} className={"me-3 mb-3"}>
                    {filter?.type === 'text' &&
                        <Input
                            rootClassName={'w-full'}
                            value={filteringValue ? filteringValue[filter?.field + ":" + (filter?.operator || 'contain')] : ''}
                            onChange={(e) => {
                                if (e?.target?.value) {
                                    setFilteringValue({
                                        ...filteringValue,
                                        [filter?.field + ":" + (filter?.operator || 'contain')]: e.target.value
                                    })
                                } else {
                                    const inKey = filter?.field + ":" + (filter?.operator || 'contain');
                                    const {[inKey]: removed, ...newFilteringValue} = filteringValue;
                                    setFilteringValue(newFilteringValue);
                                }
                            }} placeholder={filter?.label}

                            onPressEnter={() => {
                                setQuery({
                                    ...query,
                                    filtering: filteringValue
                                })
                            }}
                        />
                    }
                    {filter?.type === 'number' &&
                        <InputNumber
                            className={'w-100'}
                            value={filteringValue ? filteringValue[filter?.field + ":" + (filter?.operator || 'eq')] : ''}
                            onChange={(e) => {
                                if (e) {
                                    setFilteringValue({
                                        ...filteringValue,
                                        [filter?.field + ":" + (filter?.operator || 'eq')]: e
                                    })
                                } else {
                                    const inKey = filter?.field + ":" + (filter?.operator || 'eq');
                                    const {[inKey]: removed, ...newFilteringValue} = filteringValue;
                                    setFilteringValue(newFilteringValue);
                                }
                            }} placeholder={filter?.label}

                            onPressEnter={() => {
                                setQuery({
                                    ...query,
                                    filtering: filteringValue
                                })
                            }}
                        />
                    }
                    {filter?.type === 'select_multiple' &&
                        <Select
                            className={'w-100'}
                            mode="multiple"
                            allowClear
                            placeholder={filter?.label}
                            onChange={(r) => {
                                if (r) {
                                    setQuery({
                                        ...query,
                                        filtering: {
                                            ...filteringValue,
                                            [filter?.field + ":in"]: r.value
                                        }
                                    })
                                } else {
                                    const inKey = filter?.field + ":in";
                                    const {[inKey]: removed, ...newFilteringValue} = filteringValue;
                                    setFilteringValue(newFilteringValue);
                                }
                            }}
                            options={[]}
                        />
                    }
                    {filter?.type === 'select' &&
                        <Select
                            className={'w-100'}
                            allowClear
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={filter?.style ?? {
                                minWidth: "10rem"
                            }}
                            value={filter?.options.find((v) => v.value === parseInt(filteringValue ? filteringValue[filter?.field] : null))}
                            placeholder={filter?.label}
                            onChange={(r) => {
                                if (r !== null) {
                                    setQuery({
                                        ...query,
                                        filtering: {
                                            ...filteringValue,
                                            [filter?.field]: r
                                        }
                                    })
                                } else {
                                    const inKey = filter?.field;
                                    const {[inKey]: removed, ...newFilteringValue} = filteringValue;
                                    setFilteringValue(newFilteringValue);
                                }

                            }}
                            options={filter?.options || []}
                        />
                    }
                </Col>
            )}
        </div>
    )
}

export default FilteringComponent