import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllStudentsAction = async (query) => {
    return await (new Api()).get('students', query, getAuthHeader());
}
export const getAllSharedStudentsAction = async (query) => {
    return await (new Api()).get('students/s', query, getAuthHeader());
}

export const createStudentAction = async (data) => {
    return await (new Api()).post('students', data, getAuthHeader())
}

export const getOneStudentAction = async (id) => {
    return await (new Api()).get(`students/${id}`, {}, getAuthHeader())
}

export const updateStudentAction = async (data,id) => {
    return await (new Api()).put(`students/${id}`, data, getAuthHeader())
}
