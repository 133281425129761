import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import Dragger from "antd/es/upload/Dragger";
import {BackwardFilled, DownloadOutlined, InboxOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import CustomLink from "../../_components/CustomLink";
import {url} from "../../_classes/Helper";
import {getCurrentBranchImportInfo, importAction, validateImportAction} from "../../_api/ImportApi";
import Notification from "../../_classes/Notification";
import {Spin} from "antd";

const ImportScreen = () => {
    const {entity} = useParams()

    const [isImportSuccess, setIsImportSuccess] = useState(false)
    const [onUpload, setOnUpload] = useState(false)
    const [errors, setErrors] = useState([])
    const mapperEntityLabel = {
        teacher: "Giáo viên",
        supporter: "Trợ giảng",
        staff: 'Nhân viên',
        classroom: 'Lớp học',
        student: "Học sinh"

    }
    const nav = useNavigate()
    const handleValidateAction = (file) => {
        return true;
    }
    useEffect(() => {
        getCurrentBranchImportInfo().then((r)=>{
            const info = r?.data

            if(! info?.user_ability?.[`can_import_${entity}`]) {
                Notification.error('Đã Import trước đó')
                nav('/')
            }
        })
    }, []);
    return (
        <AuthenticatedLayout>
            <div className={"my-2 text-purple-bright fw-bold"}>
                <BackwardFilled/>
                <CustomLink to={"/"}>Quay về trang chủ</CustomLink>
            </div>
            {!isImportSuccess &&
                <div>
                    <Dragger
                        customRequest={handleValidateAction}
                        maxCount={1}
                        name={'file'}
                        beforeUpload={(file, fileList) => {
                            let isSuccess = false
                            validateImportAction({entity: entity, file: file, setLoading: setOnUpload}).then((r) => {
                                if (r?.success) {
                                    importAction({data: r?.data?.data, entity: entity,setLoading:setOnUpload}).then((res) => {
                                        if (res?.success) {
                                            setIsImportSuccess(true)
                                        } else {
                                        }
                                        setOnUpload(false)
                                    });
                                } else {
                                    setOnUpload(false)
                                    if (r?.code === 400) {
                                        setErrors(r?.errors)
                                    } else {
                                        Notification.error(r?.message)
                                        return false
                                    }
                                }
                            })

                            return isSuccess
                        }}
                        accept={'.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                    >
                        <p className="ant-upload-drag-icon">
                            {onUpload &&
                                <Spin size="large"/>}
                            {!onUpload &&
                                <InboxOutlined/>
                            }
                        </p>
                        {!onUpload &&
                            <p className="ant-upload-text">Tải file {mapperEntityLabel[entity]} lên</p>
                        }
                        {onUpload &&
                            <p className="ant-upload-text">Đang tải lên</p>
                        }
                        <p className="ant-upload-hint">
                            {UploadDetailLabel[entity] ?? ''}
                        </p>
                    </Dragger>
                    <a target={"_blank"} href={url(`import/template/${entity}`)}>
                        <div className={"my-2 cursor-pointer text-purple-bright"}>
                            <DownloadOutlined/>
                            <span className={"ms-2"}>Download file excel mẫu</span>
                        </div>
                    </a>
                </div>
            }

            {isImportSuccess &&
                <div className={"h-full d-flex justify-content-center align-items-center"}>
                    <div className={"text-center"}>
                        <img className={"mb-3"} style={{width: 100}}
                             src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9sDRJRvcQEroYhiKVwUpkfrO_MPVpmmSQxg&s"}/>
                        <div className={"h3 text-success"}> Tải lên thành công</div>
                        <div className={"text-purple-bright fw-bold"}><CustomLink to={'/'}>Quay về trang
                            chủ</CustomLink></div>
                    </div>
                </div>
            }

            {errors?.length > 0 &&
                <div className={"w-full"}>
                    <table className="table table-bordered table-hover">
                        <thead className="">
                        <tr>
                            <th scope="col">Dòng</th>
                            <th scope="col">Nội dung lỗi</th>
                        </tr>
                        </thead>
                        <tbody>
                        {errors?.map((error, key) => (
                            <tr key={key}>
                                <td>{error?.row}</td>
                                <td>
                                    {error?.excelErrorsData?.map((excelError, k) =>
                                        <div key={k}>{excelError?.label} : {excelError?.message}</div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </AuthenticatedLayout>
    )
}

const UploadDetailLabel = {
    staff: "Nếu mã nhân viên trùng với mã nhân viên trên hệ thống, nhân viên đó sẽ được cập nhật lại thông tin",
    teacher: "Nếu mã giáo viên trùng với mã giáo viên trên hệ thống, giáo viên đó sẽ được cập nhật lại thông tin",
    supporter: "Nếu mã trợ giảng trùng với mã trợ giảng trên hệ thống, trợ giảng đó sẽ được cập nhật lại thông tin",
    student: "Nếu mã học sinh trùng với mã học sinh trên hệ thống, học sinh đó sẽ được cập nhật lại thông tin",
    classroom: "Nếu mã lớp học trùng với mã lớp học trên hệ thống, lớp học đó sẽ được cập nhật lại thông tin",
}

export default ImportScreen