import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams, mapSelectData} from "../../_classes/Helper";
import {getAllSupportersAction} from "../../_api/SupporterApi";
import {getAllSharedClassroomsAction} from "../../_api/ClassroomApi";
import {getAllSharedStudentsAction} from "../../_api/StudentApi";

const ListSupporterScreen = () => {
    const location = useLocation();
    const [supporterCollection, setSupporterCollection] = useState({})
    const [sharedClassrooms, setSharedClassrooms] = useState([])
    const nav = useNavigate()
    const [query, setQuery] = useState()
    useEffect(() => {
        getAllSharedClassroomsAction({
            fields: 'id,uuid,name'
        }).then((r) => {
            console.log(r?.data)
            if (r?.success) {
                setSharedClassrooms(r?.data?.data)
            }
        })
    }, [])
    const columnStruct = [
        {
            title: 'Trợ giảng',
            dataIndex: 'id',
            key: 'id',
            type: "user_info",
            fixed: 'left'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'supporter_status_name',
            key: 'supporter_status_name',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            type: 'phone'
        },

        {
            title: 'Facebook',
            dataIndex: 'facebook',
            key: 'facebook',
            options:{
                custom_key: 'facebook-tro-giang',
                key_type:'link'
            },
            type: 'extra_information'
        },
        {
            title: 'Email trợ giảng',
            dataIndex: 'email',
            key: 'email',
            type: 'email'
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 150
        }
    ];
    const filteringStructs = [
        {
            field: "name",
            type: "text",
            label: "Tên trợ giảng"
        },
        {
            field: "uuid",
            type: "text",
            label: "Mã trợ giảng"
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
        },
        {
            field: "email",
            type: "text",
            label: "Email",
        },
        {
            field: "address",
            type: "text",
            label: "Địa chỉ",
        },
        {
            field: "zalo",
            type: "text",
            label: "Link Zalo nhóm phụ huynh",
        },
        {
            field: "age",
            type: "number",
            label: "Tuổi"
        },
        {
            field: "status",
            type: "select",
            label: "Trạng thái",
            options: [
                {value: 1, label: 'Đang làm'},
                {value: 2, label: 'Tạm nghỉ'},
                {value: 3, label: 'Đã nghỉ'},
            ]
        },
        {
            field: "classroom",
            type: "select",
            label: "Theo lớp học",
            options: mapSelectData({
                key: 'id',
                label: 'uuid',
                prefix: 'name',
                data: sharedClassrooms
            }),
            col:6
        },
        {
            field: "day",
            type: "number",
            label: "Ngày sinh"
        },
        {
            field: "month",
            type: "number",
            label: "Tháng sinh"
        },
        {
            field: "year",
            type: "number",
            label: "Năm sinh"
        },

    ]

    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            getAllSupportersAction(query).then((response) => {
                setSupporterCollection(response?.data)
            }).catch()
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            fields: "id,uuid,name,phone,email,last_active,user_abilities,avatar,supporter_status_name,supporter_status",
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering || null,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách trợ giảng</div>
            <div className={"d-flex justify-content-between mt-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
            </div>
            <div className={"mb-3"}><Button onClick={() => nav('/supporter/create')} className={'btn-purple-bright'}>Thêm mới</Button></div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={supporterCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setSupporterCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListSupporterScreen