import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {getAllStaffsAction} from "../../_api/StaffApi";
import {handleCreateUrlParams, handleParseUrlParams, mapSelectData} from "../../_classes/Helper";

const ListStaffsScreen = () => {
    const location = useLocation();
    const [staffsCollection, setStaffsCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const columnStruct = [
        {
            title: 'Tên nhân viên',
            dataIndex: 'id',
            key: 'id',
            type: "user_info",
        },
        {
            title: 'Chức vụ',
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'staff_status_name',
            key: 'staff_status_name',
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email nhân viên',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Facebook',
            dataIndex: 'facebook',
            key: 'facebook',
            options: {
                custom_key: 'facebook-nhan-vien',
                key_type: 'link'
            },
            type: 'extra_information'
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities"
        }
    ];
    const filteringStructs = [
        {
            field: "uuid",
            type: "text",
            label: "Mã nhân viên"
        },
        {
            field: "name",
            type: "text",
            label: "Tên nhân viên"
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
        },
        {
            field: "email",
            type: "text",
            label: "Email",
        },
        {
            field: "address",
            type: "text",
            label: "Địa chỉ",
        },
        {
            field: "year",
            type: "text",
            label: "Năm sinh",
        },
        {
            field: "position",
            type: "text",
            label: "Chức vụ",
        },
        {
            field: "status",
            type: "select",
            label: "Trạng thái",
            options: [
                {value: 1, label: 'Đang làm'},
                {value: 2, label: 'Tạm nghỉ'},
                {value: 3, label: 'Đã nghỉ'},
            ]
        }
    ]

    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            getAllStaffsAction(query).then((response) => {
                setStaffsCollection(response?.data)
            }).catch()
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            fields: "id,uuid,name,phone,email,last_active,user_abilities,avatar,position,staff_status,extra_information,staff_status_name",
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách nhân viên</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/staff/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={staffsCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setStaffsCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListStaffsScreen