import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {Avatar, Button, Card, Col, Row, Tabs} from "antd";
import Title from "antd/es/skeleton/Title";
import TabPane from "antd/es/tabs/TabPane";
import {useEffect, useState} from "react";
import {getOneStaffAction} from "../../_api/StaffApi";
import {useParams} from "react-router-dom";
import {getOneTeacherAction} from "../../_api/TeacherApi";
import CustomLink from "../../_components/CustomLink";
import {getOneSupporterAction} from "../../_api/SupporterApi";

const ShowSupporterScreen = () => {
    const [supporter, setSupporter] = useState({})
    const {id} = useParams()
    useEffect(() => {
        getOneSupporterAction(id).then(r => {
            if (r?.success) {
                setSupporter(r?.data)
            }
        })
    }, []);

    const userInfo = {
        coverPhoto: "https://asset.gecdesigns.com/img/wallpapers/beautiful-magical-misty-mountains-reflection-river-ultra-hd-wallpaper-4k-sr10012420-1706505766369-cover.webp", // Placeholder cho ảnh bìa
    };

    return (
        <AuthenticatedLayout
            breadcrumb={{
                first: {
                    label: "Trợ giảng",
                    to: "/supporter"
                }
            }}
        >
            <div style={{fontFamily: "Arial, sans-serif"}}>
                {/* Header */}
                <div style={{position: "relative"}}>
                    <img
                        src={userInfo.coverPhoto}
                        alt="Cover"
                        style={{width: "100%", height: "250px", objectFit: "cover"}}
                    />
                    <Avatar
                        src={supporter.avatar}
                        size={100}
                        style={{
                            position: "absolute",
                            bottom: "-50px",
                            left: "20px",
                            border: "3px solid white",
                        }}
                    />
                </div>

                {/* User Info */}
                <div style={{padding: "70px 20px 20px", background: "#fff"}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2 style={{margin: 0}}>{supporter.name}</h2>
                            <p style={{margin: 0, color: "gray"}}>{supporter?.extra_infomation?.chuc_vu}</p>
                        </Col>
                        <Col>
                            {/*<Button type="primary" style={{marginRight: "10px"}}>*/}
                            {/*    Thêm bạn bè*/}
                            {/*</Button>*/}
                            <Button type="default">Nhắn tin</Button>
                        </Col>
                    </Row>
                </div>

                {/* Tabs */}
                <div style={{marginTop: "20px", background: "#fff", padding: "0 20px"}}>
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="Thông tin" key="2">
                            <div>
                                <span
                                    className={"fw-bold"}>Mã Trợ giảng</span><span>: {supporter?.uuid || 'Chưa có thông tin'}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Trạng thái</span><span>: {supporter?.supporter_status_name || 'Chưa có thông tin'}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Ngày sinh</span><span>: {supporter?.birthday || 'Chưa có thông tin'}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Số ngày còn lại tới sinh nhật	</span><span>: {supporter?.my_birthday || 'Chưa có thông tin'}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Tuổi</span><span>: {supporter?.age || 'Chưa có thông tin'}</span>
                            </div>
                            <div>
                                <span
                                    className={"fw-bold"}>Số điện thoại</span><span>: {supporter?.phone || 'Chưa có thông tin'}</span>
                            </div>
                            <div><span className="fw-bold">Email</span>: {supporter?.email || 'Chưa có thông tin'}</div>
                            <div><span
                                className="fw-bold">Facebook</span>: {supporter?.extra_information?.["facebook-tro-giang"] || 'Chưa có thông tin'}
                            </div>
                            <div><span
                                className="fw-bold">Địa chỉ</span>: {supporter?.extra_information?.["dia-chi-thuong-tru-giao-tro-giang"] || 'Chưa có thông tin'}
                            </div>
                            <div><span
                                className="fw-bold">Tên ngân hàng nhận lương</span>: {supporter?.extra_information?.["ten-ngan-hang-nhan-luong-cua-tro-giang"] || 'Chưa có thông tin'}
                            </div>
                            <div><span
                                className="fw-bold">Số tài khoản ngân hàng</span>: {supporter?.extra_information?.["so-tai-khoan-ngan-hang-nhan-luong-tro-giang"] || 'Chưa có thông tin'}
                            </div>
                            <div><span
                                className="fw-bold">Tên chủ tài khoản ngân hàng</span>: {supporter?.extra_information?.["ten-chu-tai-khoan-ngan-hang-nhan-luong-tro-giang"] || 'Chưa có thông tin'}
                            </div>
                            {/*<div><span*/}
                            {/*    className="fw-bold">Lớp học đang phân công</span>: {supporter?.assigned_class || 'Chưa có thông tin'}*/}
                            {/*</div>*/}
                            {/*<div><span*/}
                            {/*    className="fw-bold">Học sinh đang phân công</span>: {supporter?.inf_student || 'Chưa có thông tin'}*/}
                            {/*</div>*/}
                        </TabPane>
                        <TabPane tab="Lớp học đang được phân công" key="3">
                            {supporter?.classroom_supporter !== []
                                ?
                                <div>
                                    {supporter?.classroom_supporter?.map((classroom, key) =>
                                        <CustomLink key={key} to={`/classroom/show/${classroom?.id}`}>
                                            <div className={"border mb-3 p-2 d-flex align-items-center"}>
                                                <div>
                                                    <img src={classroom?.avatar} style={{width: 40}}/>
                                                </div>
                                                <div>
                                                    <div
                                                        className={"text-purple-bright fw-bold"}>{classroom?.name}</div>
                                                    <div>Mã: {classroom?.uuid}</div>
                                                </div>
                                            </div>
                                        </CustomLink>
                                    )}
                                </div>
                                :
                                <div>Không có dữ liệu</div>
                            }
                        </TabPane>
                        <TabPane tab="Học sinh đang phân công" key="4">
                            {supporter?.inf_student !== []
                                ?
                                <div>
                                    {supporter?.inf_student?.map((student, key) =>
                                        <CustomLink key={key} to={`/student/show/${student?.id}`}>
                                            <div className={"border mb-3 p-2 d-flex align-items-center"}>
                                                <div>
                                                    <img alt={""} src={student?.avatar} style={{width: 40}}/>
                                                </div>
                                                <div>
                                                    <div
                                                        className={"text-purple-bright fw-bold"}>{student?.name}</div>
                                                    <div>Mã: {student?.uuid}</div>
                                                </div>
                                            </div>
                                        </CustomLink>
                                    )}
                                </div>
                                :
                                <div>Không có dữ liệu</div>
                            }
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default ShowSupporterScreen