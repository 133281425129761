import {Col, Progress, Row} from "antd";
import {CheckCircleFilled, PlayCircleFilled, WarningFilled} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {getCurrentBranchImportInfo} from "../../_api/ImportApi";
import {useNavigate} from "react-router-dom";

const HomeImportStepComponent = () => {
    const [importData, setImportData] = useState({})
    useEffect(() => {
        getCurrentBranchImportInfo().then((r) => {
            setImportData(r?.data)
        })
    }, []);
    const nav = useNavigate()
    return (
        <div className={"bg-white shadow-sm p-2 w-100"}>
            <div className={"fw-bold text-purple-bright text-center h6 my-2 mb-3"}>Số hoá trung tâm của bạn</div>
            <div className={"w-100"}>
                <div className={"my-2 text-center"}>
                    <Progress type="circle" percent={importData?.percent}/>
                </div>
                <div>
                    {importData?.has_import_staff === 1 ?
                        <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import nhân
                            viên <CheckCircleFilled className={"ms-1"}/></div> :
                        <div onClick={() => {
                            nav('/import/staff')
                        }}
                             className={"cursor-pointer text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import
                            nhân viên <PlayCircleFilled className={"ms-1"}/></div>
                    }

                    {importData?.has_import_teacher === 1 ?
                        <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import giáo
                            viên <CheckCircleFilled className={"ms-1"}/></div> :
                        <div onClick={() => {
                            nav('/import/teacher')
                        }}
                             className={"cursor-pointer text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import
                            giáo viên <PlayCircleFilled className={"ms-1"}/></div>
                    }

                    {importData?.has_import_supporter === 1 ?
                        <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import trợ
                            giảng <CheckCircleFilled className={"ms-1"}/></div> :
                        <div onClick={() => {
                            nav('/import/supporter')
                        }}
                             className={"cursor-pointer text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import
                            trợ giảng <PlayCircleFilled className={"ms-1"}/></div>
                    }

                    {importData?.has_import_classroom === 1 ?
                        <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import lớp học <CheckCircleFilled className={"ms-1"}/></div> :
                        <div onClick={() => {
                            nav('/import/classroom')
                        }}
                             className={"cursor-pointer text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import
                           lớp học <PlayCircleFilled className={"ms-1"}/></div>
                    }

                    {importData?.has_import_student === 1 ?
                        <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import học sinh <CheckCircleFilled className={"ms-1"}/></div> :
                        <div onClick={() => {
                            nav('/import/student')
                        }}
                             className={"cursor-pointer text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import
                            học sinh <PlayCircleFilled className={"ms-1"}/></div>
                    }
                </div>
            </div>
        </div>
    )
}
export default HomeImportStepComponent