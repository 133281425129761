import CustomLink from "../CustomLink";
import {Modal} from "antd";
import {useState} from "react";

const ParentStudentContactColumn = ({value, record, type}) => {
    return (
        <div className={"text-start cursor-pointer"}>
            {type === 'mother' && (
                <>
                    {record?.extra_information?.['ten-me'] && (
                        <div>
                            <span className="fw-bold">Tên mẹ:</span> {record.extra_information['ten-me']}
                        </div>
                    )}
                    {record?.extra_information?.['ngay-sinh-me'] && (
                        <div>
                            <span className="fw-bold">Năm sinh mẹ:</span> {(new Date(record.extra_information['ngay-sinh-me'])).toLocaleDateString()}
                        </div>
                    )}
                    {record?.extra_information?.['nghe-nghiep-me'] && (
                        <div>
                            <span className="fw-bold">Nghề nghiệp:</span> {record.extra_information['nghe-nghiep-me']}
                        </div>
                    )}
                    {record?.extra_information?.['sdt-me'] && (
                        <div>
                            <span className="fw-bold">SĐT:</span> {record.extra_information['sdt-me']}
                        </div>
                    )}
                    {record?.extra_information?.['facebook-me'] && (
                        <div>
                            <span className="fw-bold">FB:</span> {record.extra_information['facebook-me']}
                        </div>
                    )}
                    {record?.extra_information?.['email-me'] && (
                        <div>
                            <span className="fw-bold">Email:</span> {record.extra_information['email-me']}
                        </div>
                    )}
                </>
            )}
            {type === 'father' && (
                <>
                    {record?.extra_information?.['ten-bo'] && (
                        <div>
                            <span className="fw-bold">Tên bố:</span> {record.extra_information['ten-bo']}
                        </div>
                    )}
                    {record?.extra_information?.['ngay-sinh-bo'] && (
                        <div>
                            <span className="fw-bold">Năm sinh bố:</span> {(new Date(record.extra_information['ngay-sinh-bo'])).toLocaleDateString()}
                        </div>
                    )}
                    {record?.extra_information?.['nghe-nghiep-bo'] && (
                        <div>
                            <span className="fw-bold">Nghề nghiệp:</span> {record.extra_information['nghe-nghiep-bo']}
                        </div>
                    )}
                    {record?.extra_information?.['sdt-bo'] && (
                        <div>
                            <span className="fw-bold">SĐT:</span> {record.extra_information['sdt-bo']}
                        </div>
                    )}
                    {record?.extra_information?.['facebook-bo'] && (
                        <div>
                            <span className="fw-bold">FB:</span> {record.extra_information['facebook-bo']}
                        </div>
                    )}
                    {record?.extra_information?.['email-bo'] && (
                        <div>
                            <span className="fw-bold">Email:</span> {record.extra_information['email-bo']}
                        </div>
                    )}
                </>
            )}
        </div>

    )
}


export default ParentStudentContactColumn