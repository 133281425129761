import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {getAllStaffsAction} from "../../_api/StaffApi";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {getAllClassroomsAction} from "../../_api/ClassroomApi";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch} from "react-redux";

const ListStaffsScreen = () => {
    const location = useLocation();
    const [classroomCollection, setClassroomCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const columnStruct = [
        {
            title: 'Lớp học',
            dataIndex: 'id',
            key: 'id',
            type: "user_info",
            width: 300
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            type: "select",
            options: [
                {
                    value: 1,
                    label: "Đang chạy",
                    rootClassName: "text-success"
                },
                {
                    value: 2,
                    label: "Tạm dừng",
                    rootClassName: "text-waring"
                },
                {
                    value: 3,
                    label: "Đã dừng",
                    rootClassName: "text-secondary"
                },
            ]
        },
        {
            title: "Lịch học",
            dataIndex: "schedules",
            key: "schedules",
            type: "classroom_schedules"
        },
        {
            title: "Ngày cập nhật gần nhất",
            dataIndex: "updated_at",
            key: "updated_at",
            type: "date"
        },
        {
            title: "Số buổi chạy của lớp",
            dataIndex: "total_study_logs",
            key: "total_study_logs",
            type: "number"
        },
        {
            title: "Sĩ số - Số lượng thẻ học đang đc xếp",
            dataIndex: "number_of_student",
            key: "number_of_student",
            type: "number"
        },
        {
            title: "Tổng số buổi đi học của học sinh",
            dataIndex: "number_of_school_days",
            key: "number_of_school_days",
            type: "number"
        },
        {
            title: "Tổng số buổi vắng của học sinh",
            dataIndex: "number_of_left_days",
            key: "number_of_left_days",
            type: "number"
        },
        {
            title: "Sĩ số trung bình / buổi",
            dataIndex: "avg_student_school_days",
            key: "avg_student_school_days",
            type: "number"
        },
        {
            title: "Doanh thu thực",
            dataIndex: "revenue",
            key: "revenue",
            type: "number"
        },
        {
            title: "Chi lương giáo viên VN",
            dataIndex: "internal_salary",
            key: "internal_salary",
            type: "number"
        },
        {
            title: "Chi lương giáo viên nước ngoài",
            dataIndex: "external_salary",
            key: "external_salary",
            type: "number"
        },
        {
            title: "Chi lương trợ giảng",
            dataIndex: "supporter_salary",
            key: "supporter_salary",
            type: "number"
        },
        {
            title: "Lãi gộp",
            dataIndex: "gross",
            key: "gross",
            type: "number"
        },
        {
            title: "% Lãi gộp",
            dataIndex: "gross_percent",
            key: "gross_percent",
            type: "number"
        },
        {
            title: "Tình trạng lớp",
            dataIndex: "gross_status",
            key: "gross_status",
            type: "number"
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 100
        }
    ];
    const filteringStructs = [
        {
            field: "teacher_id",
            type: "text",
            label: "Giáo viên",
            col:6
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
            col:6
        },
        {
            field: "email",
            type: "text",
            label: "Email",
            col:6
        },
        // {
        //     field: "status",
        //     type: "select",
        //     label: "Tình trạng",
        //     options: mapSelectData({key: 'id', label: 'name', data: status})
        // }
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState: true}))
            getAllClassroomsAction(query).then((response) => {
                if (response?.success) {
                    setClassroomCollection(response?.data)
                    dispatch(commonAction({loadingState: false}))
                } else {
                    dispatch(commonAction({loadingState: false}))
                }
            }).catch(() => {
                dispatch(commonAction({loadingState: false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)
        setQuery({
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách lớp học</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/classroom/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={classroomCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setClassroomCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListStaffsScreen