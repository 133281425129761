import DataTableComponent from "../../_components/DataTableComponent";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams, mapSelectData} from "../../_classes/Helper";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch, useSelector} from "react-redux";
import {getAllSalaryConfigAction, syncSalaryConfigAction} from "../../_api/SalaryCriteriaConfig";
import {getAllSharedClassroomsAction} from "../../_api/ClassroomApi";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import Notification from "../../_classes/Notification";
const ListSalaryConfigScreen = () => {
    const location = useLocation();
    const [salaryConfigsCollection, setSalaryConfigsCollection] = useState({})
    const [sharedClassrooms, setSharedClassrooms] = useState([])
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const commonState = useSelector((state) => state.common)
    const columnStruct = [
        {
            title: 'Nhân sự',
            dataIndex: 'user_id',
            key: 'user_id',
            type: "user_info",
            width: 300
        },
        {
            title: 'Lớp học',
            dataIndex: 'classroom_id',
            key: 'classroom_id',
            type: "classroom_info",
            width: 300
        },
        {
            title: 'Lương theo giờ',
            dataIndex: 'salary_rate',
            key: 'salary_rate',
            type: 'number'
        },
        {
            title: 'Quỹ gắn bó (%)',
            dataIndex: 'fund_percent',
            key: 'salary_rate',
            type: 'number'
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 100
        }
    ];
    const filteringStructs = [
        {
            field: "user_id",
            type: "select",
            label: "Nhân sự",
            options: mapSelectData({
                key: 'id',
                label: 'uuid',
                prefix: 'name',
                data: commonState?.users || []
            }),
            style: {
                width: "20rem"
            },
            col:6
        },
        {
            field: "classroom_id",
            type: "select",
            label: "Lớp học",
            style: {
                width: "20rem"
            },
            options: mapSelectData({
                key: 'id',
                label: 'uuid',
                prefix: 'name',
                data: sharedClassrooms || []
            }),
            col:6
        }
    ]

    const dispatch = useDispatch()
    useEffect(() => {
        getAllSharedClassroomsAction().then(r => {
            setSharedClassrooms(r?.data?.data)
        })
    }, [])
    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState: true}))
            getAllSalaryConfigAction(query).then((response) => {
                if (response?.success) {
                    setSalaryConfigsCollection(response?.data)
                    dispatch(commonAction({loadingState: false}))
                } else {
                    dispatch(commonAction({loadingState: false}))
                }
            }).catch(() => {
                dispatch(commonAction({loadingState: false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)
        setQuery({
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    function handleSyncSalary() {
        syncSalaryConfigAction().then((r) => {
            Notification.success("Thành công")
            if (r?.success) {
                getAllSalaryConfigAction(query).then((response) => {
                    if (response?.success) {
                        setSalaryConfigsCollection(response?.data)
                        dispatch(commonAction({loadingState: false}))
                    } else {
                        dispatch(commonAction({loadingState: false}))
                    }
                }).catch(() => {
                    dispatch(commonAction({loadingState: false}))
                })
            }
        })
    }

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách cấu hình lương</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => handleSyncSalary()} className={'btn-purple-bright'}>Đồng bộ cấu hình</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={salaryConfigsCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setSalaryConfigsCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListSalaryConfigScreen